$red:#b00020;
select {
  -webkit-appearance: none;
  appearance: none;
  background: none;
  background-image: url(/assets/images/svg/down-chevron.svg);
  background-size: 12px;
  background-position: 96% center;
  background-repeat: no-repeat;
}

*{
  font-family: 'Inter';
}
textarea {
  resize: none;
}
.radio-button{
  $border-color: $purple;
  cursor: pointer;
-webkit-appearance: none;
  appearance: none;
width: 18px;
height: 18px;
border-radius: 50%;
outline: none;
border: 1.4px solid #21252A;
&:before {
  content: '';
  display: block;
  width: 70%;
  height: 70%;
  margin: 15% auto;
  border-radius: 50%;
}

&:checked {
  &:before {
    background: $purple;
  }
  border-color: $border-color;
}

  }
.w__full{
    width: 100%;
}
.error__message{
  color: $red;
  font-size: 14px;
}
.cursor__pointer{
  cursor: pointer;
}
.form__group{
  display: flex;
  flex-direction: column;
  gap: 0rem;
  @include desktop{
      display: flex;
      flex-direction: row;
      gap: 1.2rem;
  }
}
.item__label{
  border-radius: 90px;
  background: $orange;
  color: #FFFFFF;
  padding: 5px 8px;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
}

.form__wrapper{
    margin: .3rem 0;
    
   .form__input{
        border: 1px solid #D2D0D0;
        border-radius: 4px;
        //background: transparent;
        height: 48px;
        font-size: $font-size-2;
        letter-spacing: normal;
        line-height: normal;
        padding: 6px 16px;
        outline: $purple !important;
        margin-top: 8px;
        @include medium-font;
        &::placeholder {
          color: #A3A1A1;
          @include primary-font;
        }
        
        &:focus{
            border:1px solid $purple;
        }
        &.error {
          border: solid 1px $red;
         
         
        }
        &.disabled {
          background-color: #f5f5f7;
          cursor: not-allowed;
        }
    }
    
    .form__textarea{
      border: 1px solid #D2D0D0;
      border-radius: 4px;
      background: transparent;
      height: auto;
      font-size: $font-size-2;
      letter-spacing: normal;
      line-height: normal;
      padding: 6px 16px;
      outline: $purple !important;
      margin-top: 8px;
      @include medium-font;
      &::placeholder {
        color: #A3A1A1;
        @include primary-font;
      }
     
      &:focus{
          border:1px solid $purple;
      }
      &.error {
        border: solid 1px $red;
        
        
      }
      &.disabled {
        background-color: #f5f5f7;
        cursor: not-allowed;
      }
  }

.form__label{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $color-black;
  font-family: 'Inter';

}
}


.input__group{
  display: flex;
  
  .input__group__input{
    width: 100%;
    border: 1px solid #D2D0D0;
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: transparent;
    height: 48px;
    font-size: $font-size-2;
    letter-spacing: normal;
    line-height: normal;
    padding: 6px 16px;
    outline: $purple !important;
    margin-top: 8px;
    @include medium-font;
    &::placeholder {
      color: #A3A1A1;
      @include primary-font;
    }
   
    &:focus{
        border:1px solid $purple;
    }
    &.error {
      border: solid 1px $red;
     
     
    }
    &.disabled {
      background-color: #f5f5f7;
      cursor: not-allowed;
    }
}

  .input__group__button{
        border: 1px solid #D2D0D0;
        border-right:none ;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        background: transparent;
        height: 48px;
        font-size: $font-size-2;
        letter-spacing: normal;
        line-height: normal;
        padding: 6px 16px;
        outline: $purple !important;
        margin-top: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.error {
          border: solid 1px $red;
          border-right: 0px;
          
          
        }
  }
}