.overview{
    
    .overview__metrics{
        margin: 2rem auto;
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        justify-content: center;
       @include desktop{
        margin: 2rem auto;
        display: flex;
        flex-wrap: nowrap;
        gap: 1rem;
        justify-content: center;
       }
        .metric__card{
            width: 100%;
            min-height: 100px;
            box-shadow: 0px 2px 10px rgba(175, 137, 255, 0.15);
            border-radius: 10px;
            // padding: .8rem;
            @include desktop{
            width: 25%;
            min-height: 120px;
            box-shadow: 0px 2px 10px rgba(175, 137, 255, 0.15);
            border-radius: 10px;
            padding: 0rem;
                
            }
            .card__content{
                padding: .8rem;
                position: relative;
            }
            .card__icon{
                margin-bottom: -.8rem;
                margin-left: -.5rem;
                padding: 0px;
                
            }
            .card__title{
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: #ffff;
            }
            .item__count{
                font-weight: 700;
                font-size: 24px;
                line-height: 39px;
                text-align: right;
                color: #ffff;
            }
            &.visits__bg{
                position: relative;
                background:linear-gradient(99deg, #9C3955 0%, #F44174 100%);;
                 
                &::before {
                    content: '';
                    position: absolute; /* Position the pseudo-element absolutely within the container */
                    bottom: -10px; 
                    left: -10px; 
                    width: 100%; /* Extend the width of the pseudo-element to cover the container */
                    height: 100%; /* Extend the height of the pseudo-element to cover the container */
                    background-image: url('/assets/images/svg/ion_stats-chart.svg');
                    background-repeat: no-repeat;
                    background-position: bottom left;   
                    
                
            }
              
               
            }
            &.products__bg{
            position: relative;
            background:linear-gradient(98.86deg, #F564A9 0%, #A34D75 100%);
             
            &::before {
                content: '';
                position: absolute; /* Position the pseudo-element absolutely within the container */
                bottom: -10px; 
                left: -10px; 
                width: 100%; /* Extend the width of the pseudo-element to cover the container */
                height: 100%; /* Extend the height of the pseudo-element to cover the container */
                background-image: url('/assets/images/svg/product-box.svg');
                background-repeat: no-repeat;
                background-position: bottom left;   
               
            
        }
    }
            &.orders__bg{
                position: relative;
                background:linear-gradient(98.86deg, #D500E3 0%, #4C1E4F 100%);
                 
                &::before {
                    content: '';
                    position: absolute; /* Position the pseudo-element absolutely within the container */
                    bottom: -10px; 
                    left: -10px; 
                    width: 100%; /* Extend the width of the pseudo-element to cover the container */
                    height: 100%; /* Extend the height of the pseudo-element to cover the container */
                    background-image: url('/assets/images/svg/truck-tick.svg');
                    background-repeat: no-repeat;
                    background-position: bottom left;   
                
                
            }
            }
            &.customers__bg{
                position: relative;
                background:linear-gradient(98.86deg, #4BA2A4 0%, #55DDE0 100%);
                 
                &::before {
                    content: '';
                    position: absolute; /* Position the pseudo-element absolutely within the container */
                    bottom: -10px; 
                    left: -10px; 
                    width: 100%; /* Extend the width of the pseudo-element to cover the container */
                    height: 100%; /* Extend the height of the pseudo-element to cover the container */
                    background-image: url('/assets/images/svg/user-box.svg');
                    background-repeat: no-repeat;
                    background-position: bottom left;   
                   
                
            }
            }
            &.revenues__bg{
                position: relative;
                background:linear-gradient(98.86deg, #B3902A 0%, #FFD352 100%);;
                 
                &::before {
                    content: '';
                    position: absolute; /* Position the pseudo-element absolutely within the container */
                    bottom: -18px; /* Adjust the top position as needed */
                    left: -10px; /* Adjust the left position as needed */
                    width: 100%; /* Extend the width of the pseudo-element to cover the container */
                    height: 100%; /* Extend the height of the pseudo-element to cover the container */
                    background-image: url('/assets/images/svg/dollar-circle.svg');
                    background-repeat: no-repeat;
                    background-position: bottom left;   
                    /* Adjust the size as needed */
                
            }
            }
        }

        
        
    }
    .report__wrapper{
        display: flex;
        flex-wrap: wrap;
        gap: 2rem;
        margin-bottom: 2rem;
       
        @include desktop{
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }

        .sales__report{
            width: 100%;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            min-height: 514px;
            height: auto;
            padding: 2rem;
            @include desktop{
                width: 62%;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                min-height: 514px;
                height: auto;
                padding: 2rem;
            }
            .report__header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title{
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 24px;
                    @include desktop{
                        font-weight: 600;
                        font-size: 20px;
                        line-height: 24px;
                    }
                }
                .report__control{
                    display: flex;
                    gap: .5rem;
                    margin-bottom: 1rem;
                    .button__download__report{
                        width: 100%;
                        color: $purple;
                        height: 34px;
    
                        border-radius: $border-radius-2;
                        border: 1px solid $purple;
                        background: transparent;
                        outline: none;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        font-size: 12px;
                        line-height: 15px;
                        border-radius: 8px;
                        padding: .8rem 1rem;
                        @include desktop{
                            height: 40px;
                            padding: .8rem 1rem;
                            line-height: 15px;
                            font-size: 12px;

                        }
                        @include medium-font;
                        &:hover {
                            text-decoration: none;
                        }
                        &:focus{
                            outline: initial;
                            
                        }
                        
                        &:disabled {
                            opacity: .7;
                            cursor: not-allowed;
                        }

                    }
                    .control__label{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        border-radius: 8px;
                        padding: .8rem .8rem;
                        cursor: pointer;
                        @include desktop{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        border-radius: 8px;
                        padding: .8rem 1rem;
                        cursor: pointer;
                        }
                    }
                    .active__control{
                        background: $purple;
                        color: #FFFFFF;
                    }
                }
            }
        }
        .top__customers{
            width: 100%;
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            max-height: 514px;
            height: auto;
            padding: 2rem;
            overflow-y: auto;
            @include desktop{
                width: 35%;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
                border-radius: 10px;
                max-height: 514px;
                height: auto;
                padding: 2rem;
                overflow-y: auto;
            }
            .customer__header{
                .title{
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 24px;
                }
            }
            .customers{
                max-height: 400px;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 1rem 0rem;
            }
            .customer__list__header{
                margin-top: 1rem;
                display: flex;
                gap: 1rem;
                font-weight: 600;
                justify-content: space-between;
            }
            .customer__list__item{
                display: flex;
                gap: 1rem;
                align-items: center;
                font-size: 14px;
                line-height: 19px;
                padding: .2rem 0rem;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                padding: 1rem 0rem;
                .user__icon{
                    background: rgba(1, 111, 185, 0.15);
                    padding: .2rem;
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    width: 36px;
                }
                .name{
                    font-weight: 600;
                }
                .email{
                    font-size: 12px;
                }
            }
        }
    }
    .recent__orders{
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        padding: 1.5rem 0rem 0rem 0rem;
        font-family: 'Inter';
       
        .title{
            padding: 0rem 1rem;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: $color-black;
        }
    }
    .table__wrapper{
        padding: 1rem 0rem 0rem 0rem;
        overflow: auto;
        border-radius: 10px;
        .dashboard__table{
            width: 100%;
            thead tr th{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: justify;
                background:  #F2F2F2;
                padding: 1rem;
            }
           
        }
    }
}