@import "utils/_bootstrap.scss";
@import "settings";
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap');


// New dashboard stylesheets
@import "layout/newDashboard/footer";
@import "layout/newDashboard/navbar";
@import "layout/newDashboard/pricingTable";
@import "layout/newDashboard/support";
@import "layout/newDashboard/signupLayout";

@import "layout/landingPage/navbar";

@import "components/button";
@import "components/input";
@import "components/form";
@import "components/pill";
@import "components/alert";
@import "components/header";
@import "components/menu";
@import "components/avatar";
@import "components/on-boarding";
@import "components/loading-list";
@import "components/list";
@import "components/text";
@import "components/modal";
@import "components/header-progress";
@import "components/checkbox";
//@import "components/radio";
@import "components/badge";
@import "components/card";
@import "components/merchant";
@import "components/loader";
@import "components/uploadprof";


//app component
@import "components/sidebar";
@import "components/topnav";
@import "components/searchbar";
@import "components/inputfloatinglabel";
@import "components/emailinput";
@import "components/dropdown";
@import "components/pagination";
@import "components/table";
@import "components/breadcrumb";
@import "components/tagsinput";
@import "components/plan-notification-banner";
@import "components/radiobutton";
@import "components/switch";
@import "components/santasales";

@import "layout/auth";
@import "layout/onboarding/onboardinglayout";
@import "layout/dashboard";


@import "layout/marketplace/searchbar";
@import "layout/marketplace/navbar";
@import "layout/marketplace/productcard";
@import "layout/marketplace/topselling";
@import "layout/marketplace/featuredstore";
@import "layout/marketplace/deals";
@import "layout/marketplace/similaritemslider";
@import "layout/marketplace/carousel";
@import "layout/marketplace/cart";
@import "layout/marketplace/mobilenav";
@import "layout/marketplace/statusbadge";
@import "layout/marketplace/spinner";

@import "pages/payment";
@import "pages/general";
@import "pages/user-account";
@import "pages/order";
@import "pages/homepage";
@import "pages/cart";
@import "pages/checkout";
@import "pages/invoice";
@import "pages/confirmation";
@import "pages/onboardingpage";
@import "pages/thirdparty";
@import "pages/newDashboard";
@import "pages/newPassword";
@import "pages/premium";
@import "pages/verifyemail";
@import "pages/initiateauth";
@import "pages/profile";
@import "pages/landingpage";
@import "pages/waitlist";
@import "pages/referer";
@import "pages/santasalesstore";
//@import "pages/buyerexperience";


//onboarding
@import "pages/storesetup";
@import "pages/products";
@import "pages/completesetup";
@import "pages/merchantstore";
@import "pages/overview";
@import "pages/settings";
@import "pages/upgradeplan";
@import "pages/verifypremiumpayment";
@import "pages/customers";
@import "pages/payments";
@import "pages/customers";
@import "pages/payment";
@import "pages/socialpages";
@import "pages/plan";
@import "pages/discounts";
@import "pages/coupon";
@import "pages/storesetting";
@import "pages/invoicedetail";

@import "pages/marketplace";
@import "pages/productdetail";
@import "pages/storelisting";
@import "pages/buyercart";
@import "pages/orderconfirmationpage";
@import "pages/myaccountpage";

@import "globals";
